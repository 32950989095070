
import { callServer } from 'next/dist/client/app-call-server'

function __build_action__(action, args) {
  return callServer(action.$$id, args)
}

/* __next_internal_action_entry_do_not_use__ {"0d548ae37d7219498d88c02d95257491df1fa49f":"generateLeadEmailURL","17ab89cde8d15eda091a5465a40bcc0ab43abf25":"generateConsumerAffiliateFormURL","1dd7fb535f9be59708125494e8da6eb37f4ff184":"getCookiePhoneNumber","3f84b35e35a0d1dd3e133a2057bd9244c704d130":"generateLeadURL","8a676029b82d4b4da9b27e0d03c80ae4b1019544":"getCookieSourceKey","a7b3d44c54be06aef73b172a755c6edcc1fbaeeb":"affiliateCookies","eebd2c5255af12129bb05a69c5b2903ce5aae367":"generatePartnersURL"} */ export var generateLeadURL = createServerReference("3f84b35e35a0d1dd3e133a2057bd9244c704d130");
import { createServerReference } from "private-next-rsc-action-client-wrapper";
export var affiliateCookies = createServerReference("a7b3d44c54be06aef73b172a755c6edcc1fbaeeb");
export var getCookieSourceKey = createServerReference("8a676029b82d4b4da9b27e0d03c80ae4b1019544");
export var getCookiePhoneNumber = createServerReference("1dd7fb535f9be59708125494e8da6eb37f4ff184");
export var generatePartnersURL = createServerReference("eebd2c5255af12129bb05a69c5b2903ce5aae367");
export var generateLeadEmailURL = createServerReference("0d548ae37d7219498d88c02d95257491df1fa49f");
export var generateConsumerAffiliateFormURL = createServerReference("17ab89cde8d15eda091a5465a40bcc0ab43abf25");

